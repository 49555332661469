import { Currency } from 'dinero.js';

export interface cashierPaymentMethodInterface {
    id: string
    isActive: boolean
    isTrashed: boolean
    profileId: string
}

export interface cashierStripePaymentMethodSetupInterface {
    id: string
    profileId: string
    accountId: string
    stripePaymentMethodId: string | null
    paymentMethodId: string | null
    state: 'activating' | 'requires_action' | 'succeeded' | 'failed' | 'canceled' | 'completed'
}

export interface cashierStripeCardInterface extends cashierPaymentMethodInterface {
    state: 'active' | 'failed' | 'syncing' | 'closing' | 'closed'
    last4: string | null
    brand: string | null
    funding: string | null
    expiryYear: number | null
    expiryMonth: number | null
    stripePaymentMethodId: string
}

export interface cashierProfileInterface {
    id: string
    accountId: string
    defaultPaymentMethodId: string | null
    isActive: boolean
    isTrashed: boolean
}

export type cashierStripePaymentMethods = cashierStripeCardInterface;
export type cashierPaymentMethods = cashierStripePaymentMethods;
export type cashierPaymentMethodSetups = cashierStripePaymentMethodSetupInterface;

export interface cashierStripeProfileInterface extends cashierProfileInterface {
    state: 'active' | 'activating' | 'closed' | 'closing' | 'disconnected' | 'failed' | 'reconnecting'
    name: string
    emailAddress: string
}

export type cashierProfileTypes = cashierStripeProfileInterface;

export interface cashierPartyInterface {
    id: string
    name: string
    email: string
}

export interface cashierCustomerAccountInterface {
    id: string
    currency: Currency
    defaultProfileId: string
    organizationId: string
    customerId: string
}

export interface cashierPaymentProviderAccountInterface {
    id: string
    party: cashierPartyInterface
    name: string
}

const isStripeProfile = (profile: cashierProfileInterface): profile is cashierStripeProfileInterface => {
    return (profile as cashierStripeProfileInterface).state !== undefined;
};

const isStripeCardPaymentMethod = (paymentMethod: cashierPaymentMethodInterface): paymentMethod is cashierStripeCardInterface => {
    return (paymentMethod as cashierStripeCardInterface).last4 !== undefined;
};

const isStripePaymentMethodSetup = (paymentMethodSetup: cashierPaymentMethodSetups): paymentMethodSetup is cashierStripePaymentMethodSetupInterface => {
    return (paymentMethodSetup as cashierStripePaymentMethodSetupInterface).stripePaymentMethodId !== undefined;
};

export default {
    isStripeProfile,
    isStripeCardPaymentMethod,
    isStripePaymentMethodSetup,
};
