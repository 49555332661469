import { router } from '@inertiajs/vue3';
import { PostHog } from 'posthog-js';
import { useNarrative } from './useNarrative';
import { serviceProviderInterface } from '@/api/repositories/serviceProviders';

export interface AnalyticsKeys {
    posthog: {
        apiKey: string
        apiHost: string
    }
}

export interface Analytics {
    initAnalytics: (keys: AnalyticsKeys) => void
    recordPageVisit: () => void
    recordEvent: (name: string, properties?: object) => void
}

export default function useAnalytics() {
    const { currentUserOptional, userHasRoleOnCurrentServiceProvider, userIsOwnerOnServiceProvider, userHasRoleOnCurrentMerchant, currentServiceProviderOptional, currentMerchantOptional } = useNarrative();

    const groups = {
        serviceProvider: currentServiceProviderOptional.value?.id?.toString() ?? '',
        merchant: currentMerchantOptional.value?.id?.toString() ?? '',
    };

    let _posthog: PostHog | undefined;

    const recordPageVisit = () => {
        _posthog?.capture('$pageview', {
            $groups: groups,
        });
    };

    const serviceProviderDomain = (serviceProvider: serviceProviderInterface | undefined): string => {
        if (!serviceProvider) return '';

        return serviceProvider.domainName
            ? `https://${serviceProvider.domainName}`
            : origin.replace('admin', serviceProvider.subdomainName).replace('demo', serviceProvider.subdomainName);
    };

    const initAnalytics = (keys: AnalyticsKeys) => {
        if (!keys.posthog.apiKey) {
            return;
        }

        import('posthog-js').then((module) => {
            _posthog = module.default;

            _posthog.init(keys.posthog.apiKey, {
                api_host: keys.posthog.apiHost,
                autocapture: false,
                cross_subdomain_cookie: false,
                enable_recording_console_log: true,
            });
            recordPostInitEvents();
        });
    };

    const recordPostInitEvents = () => {
        if (!_posthog) return;

        if (currentUserOptional.value) {
            const userProperties = {
                id: currentUserOptional.value.id,
                name: currentUserOptional.value.name,
                email: currentUserOptional.value.email,
                createdAt: currentUserOptional.value.createdAt,
                serviceProviderName: currentServiceProviderOptional.value?.name || '',
                serviceProviderUser: userHasRoleOnCurrentServiceProvider.value,
                merchantUser: userHasRoleOnCurrentMerchant.value,
                serviceProviderOwner: userIsOwnerOnServiceProvider.value,
                serviceProviderNumberOfClients: currentServiceProviderOptional.value?.merchants.length || 0,
                serviceProviderBaseUrl: serviceProviderDomain(currentServiceProviderOptional.value),
            };

            _posthog?.identify(userProperties.id.toString(), userProperties);

            if (currentMerchantOptional.value) {
                _posthog?.group('merchant', currentMerchantOptional.value.id.toString(), {
                    name: currentMerchantOptional.value.name,
                });
            }

            if (currentServiceProviderOptional.value) {
                _posthog?.group('serviceProvider', currentServiceProviderOptional.value.id.toString(), {
                    name: currentServiceProviderOptional.value.name,
                });
            }
        }
        router.on('success', () => {
            recordPageVisit();
        });
    };

    const recordEvent = (name: string, properties: object = {}) => {
        const propertiesWithGroup = Object.assign(properties, {
            $groups: groups,
        });
        _posthog?.capture(name, propertiesWithGroup);
    };

    return {
        initAnalytics,
        recordPageVisit,
        recordEvent,
    };
};
