import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import calendar from 'dayjs/plugin/calendar';
import _ from 'lodash';
import { Currency } from 'dinero.js';
import { camelCase, capitalCase } from 'change-case';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import useLocalization from './useLocalization';
import { useNarrative } from './useNarrative';

dayjs.extend(utc);
dayjs.extend(calendar);
dayjs.extend(customParseFormat);

export default function useFormatters() {
    const { browserLanguageLocale } = useLocalization();

    const date = (value: string | Dayjs) => {
        const dayjsObject = (typeof value === 'object') ? value : dayjs.utc(value);

        return dayjsObject.local().format('MMMM DD, YYYY');
    };

    /**
     * Format the given date into short date(e.g. Aug 31).
     */
    const shortDate = (value: string | Dayjs) => {
        const dayjsObject = (typeof value === 'object') ? value : dayjs.utc(value);

        return dayjsObject.local().format('MMM D');
    };
    /**
     * Format the given date as a timestamp.
     */
    const datetime = (value: string | Dayjs) => {
        const dayjsObject = (typeof value === 'object') ? value : dayjs.utc(value);

        return dayjsObject.local().format('MMMM DD, YYYY h:mm A');
    };

    /**
     * Format the given date as an ISO8601 string.
     */
    const iso8601 = (value: string | Dayjs, format?: string) => {
        const dayjsObject = (typeof value === 'object') ? value : dayjs.utc(value);

        if (format) {
            return dayjsObject.local().format(format);
        }

        return dayjsObject.local().format('YYYY-MM-DDTHH:mm:ss');
    };

    /**
     * Format the given date into a relative date.
     */
    const relative = (value: string | Dayjs): string => {
        const dayjsObject = (typeof value === 'object') ? value : dayjs.utc(value);

        return dayjsObject.local().calendar(null, {
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            lastWeek: '[last] dddd',
            nextWeek: 'dddd',
            sameElse: 'MMMM DD, YYYY',
        });
    };

    /**
     * Format the given date into a relative date with times.
     */
    const relativeWithTime = (value: string | Dayjs): string => {
        const dayjsObject = (typeof value === 'object') ? value : dayjs.utc(value);

        const secondsAgo = dayjs.utc().diff(dayjsObject, 'seconds');

        const calendar = dayjsObject.local().calendar(null, {
            lastDay: '[Yesterday at] h:mm A',
            sameDay: '[Today at] h:mm A',
            nextDay: '[Tomorrow at] h:mm A',
            lastWeek: '[last] dddd',
            nextWeek: 'dddd',
            sameElse: 'MMMM DD, YYYY',
        });

        if (secondsAgo < 0) {
            return calendar;
        }
        if (secondsAgo < 5) {
            return 'just now';
        }
        if (secondsAgo < 60) {
            return `${secondsAgo} seconds ago`;
        }
        if (secondsAgo < 3600) {
            return `${Math.floor(secondsAgo / 60)} minute${secondsAgo < 120 ? '' : 's'} ago`;
        }
        if (secondsAgo < 86400) {
            return `${Math.floor(secondsAgo / 3600)} hour${secondsAgo < 7200 ? '' : 's'} ago`;
        }

        return calendar;
    };

    /**
     * Convert the first character to upper case.
     *
     * Source: https://github.com/vuejs/vue/blob/1.0/src/filters/index.js#L37
     */
    const capitalize = (value: string) => {
        if (!value) {
            return '';
        }

        return value.toString().charAt(0).toUpperCase() + value.slice(1);
    };

    /**
     * Convert snake case string to title case
     */
    const snakeToTitleCase = (value: string) => {
        if (!value) {
            return '';
        }
        if (value === 'ebay') {
            return 'eBay';
        }

        value = value.split('_')
            .map((item) => {
                return item.charAt(0).toUpperCase() + item.substring(1);
            })
            .join(' ')
            .replace('Xl', 'XL')
            .replace('Ebay', 'eBay')
            .replace('Ltl', 'LTL')
            .replace('Ftl', 'FTL');

        return value.split(':').map((item) => {
            return item.charAt(0).toUpperCase() + item.substring(1);
        }).join(': ');
    };

    /**
     * Convert camel case string to title case
     */
    const camelToTitleCase = (value: string) => {
        if (!value) {
            return '';
        }

        return _.startCase(value);
    };

    /**
     * Convert slug case string to title case
     */
    const slugToTitleCase = (value: string) => {
        if (!value) {
            return '';
        }
        value = value.split('-').map((item) => {
            return item.charAt(0).toUpperCase() + item.substring(1);
        }).join(' ').replace('Xl', 'XL');

        return value.split(':').map((item) => {
            return item.charAt(0).toUpperCase() + item.substring(1);
        }).join(': ');
    };

    /**
     * Convert pascal case string to title case
     */
    const pascalCaseToTitleCase = (value: string) => {
        return capitalCase(value);
    };

    const numeric = (value: string | number) => {
        if (!value) {
            return '0';
        }

        return Number(value).toLocaleString();
    };

    const truncate = (text: string, length: number) => {
        return text.length > length ? `${text.slice(0, length)}...` : text;
    };

    const reverseTruncate = (text: string, length: number) => {
        return text.length > length ? `...${text.slice(text.length - length)}` : text;
    };

    const money = (amount: number, currency: Currency) => {
        return new Intl.NumberFormat(browserLanguageLocale.value, { style: 'currency', currency }).format(amount);
    };

    const sharedUrl = (url: string): string => {
        const { userHasRoleOnCurrentServiceProvider } = useNarrative();

        if (url.charAt(0) !== '/') {
            url = `/${url}`;
        }
        if (userHasRoleOnCurrentServiceProvider.value) {
            return `/staff${url}`;
        } else {
            return url;
        }
    };

    const keysToCamelCase = (objectToTransform: any) => {
        return Object
            .entries(objectToTransform)
            .reduce((carry: any, [key, value]) => {
                if (Array.isArray(value)) {
                    value = value.map(arrayValue => keysToCamelCase(arrayValue));
                } else if (typeof value === 'object' && value !== null) {
                    value = keysToCamelCase(value);
                }

                carry[camelCase(key)] = value;

                return carry;
            }, {});
    };

    // Source: https://stackoverflow.com/a/52171480
    const cyrb53Hash = (str: string, seed = 0): number => {
        let h1 = 0xDEADBEEF ^ seed;
        let h2 = 0x41C6CE57 ^ seed;
        for (let i = 0, ch; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
        }
        h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
        h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
        h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
        h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

        return 4294967296 * (2097151 & h2) + (h1 >>> 0);
    };

    return {
        date,
        datetime,
        shortDate,
        iso8601,
        relative,
        relativeWithTime,
        capitalize,
        camelToTitleCase,
        snakeToTitleCase,
        slugToTitleCase,
        pascalCaseToTitleCase,
        keysToCamelCase,
        numeric,
        truncate,
        reverseTruncate,
        money,
        sharedUrl,
        cyrb53Hash,
    };
};
