import { UnwrapRef } from 'vue';
import listings from './listings';
import axios from '@/helpers/forms/axios';
import { SparkFormType, sendForm } from '@/helpers/forms/form';

export const channelTypes = [
    'amazon',
    'shopify',
    'ebay',
    'woo_commerce',
    'custom',
] as const;
export type ChannelType = typeof channelTypes[number];

export interface channelInterface {
    id: number
    createdAt: string
    updatedAt: string
    teamId: number
    type: ChannelType
    nickname: string | null
    channelAccountId: string
    connectionStatus: string
    countryId?: string
    connectionData?: {
        countryId?: string
    }
}

interface fetchChannelResponseInterface {
    channel: channelInterface
}

const fetchChannels = async (): Promise<channelInterface[]> => {
    const response = await axios.get<{ channels: channelInterface[] }>('/api/channels');

    return response.data.channels;
};

const fetchChannel = async (channelId: number): Promise<fetchChannelResponseInterface> => {
    const response = await axios.get<fetchChannelResponseInterface>(`/api/channels/${channelId}`);

    return response.data;
};

const syncListings = async (channelId: number): Promise<void> => {
    return await axios.get(`/api/channels/${channelId}/sync`);
};

export interface importListingFormInterface {
    identifier: string
}

const importListing = async (channelId: number, form: UnwrapRef<SparkFormType<importListingFormInterface>>): Promise<void> => {
    const response = await sendForm({
        method: 'POST',
        uri: `/api/channels/${channelId}/import-listing`,
        form,
    });

    return response;
};

const activateChannel = async (channelId: number): Promise<void> => {
    return await axios.get(`/api/channels/${channelId}/activate`);
};

const deactivateChannel = async (channelId: number): Promise<void> => {
    return await axios.get(`/api/channels/${channelId}/deactivate`);
};

export interface amazonMarketplaceInterface {
    identifier: string
    country: string
}

const fetchAmazonMarketplaces = async (): Promise<amazonMarketplaceInterface[]> => {
    const response = await axios.get<amazonMarketplaceInterface[]>('/api/channels/amazon/marketplaces');

    return response.data;
};

export default {
    listings,

    fetchChannel,
    fetchChannels,
    syncListings,
    importListing,
    activateChannel,
    deactivateChannel,

    fetchAmazonMarketplaces,
};
