import { moneyInterface } from '@/compiler/types';
import axios from '@/helpers/forms/axios';

export interface statisticResult {
    arrivingShipments: number[]
    arrivingItemsCount: number
    shippedShipmentsCount?: number
    workingShipmentsCount?: number
    receivedTodayShipments: number[]
    receivedTodayItemsCount: number
    lastSevenDaysReceivedItems: number[]
    lastSevenDaysShippedItems?: number[]
    totalItemsToday: number
    totalShipmentsToday: number
    unpaidChargesCount: number
    totalUnpaidCharges: moneyInterface
    openChargesCount: number
    totalOpenCharges: moneyInterface
    orderStats?: {
        totalPendingOrder: number
        totalShippedToday: number
        shippedLastSevenDays: number[]
        totalOrderShippedLastSevenDays: number
    }
    openShipmentsItemCount: number
    shippedShipmentsItemCount: number
    itemsInInventoryCount: number
    shippedAmazonShipmentsItemCount: number
    arrivedAmazonItemCount: number
    totalInStockQuantity: number
    totalAvailableQuantity: number
    totalUnavailableQuantity: number
    totalAllocatedQuantity: number
    totalInboundQuantity: number
}

const fetchMerchantStatistics = async (): Promise<statisticResult> => {
    const response = await axios.get<statisticResult>('/api/merchant-statistics');

    return response.data;
};

const fetchServiceProviderStatistics = async (): Promise<statisticResult> => {
    const response = await axios.get<statisticResult>('/api/service-provider-statistics');

    return response.data;
};

const checkOauthSession = async (): Promise<string | null> => {
    const response = await axios.get<{ redirectUrl: string | null }>('/api/oauth/session');

    return response.data.redirectUrl;
};

export default {
    fetchMerchantStatistics,
    fetchServiceProviderStatistics,
    checkOauthSession,
};
