<template>
    <base-banner
        v-if="isBillingAccountPastDue"
        :colour="BaseColour.Red"
        text="A recent payment for your subscription has failed, please update your billing information."
        action-url="/staff/service-provider-billing"
        action-text="Update Billing Information"
        :is-external-url="true"
    />
</template>

<script lang="ts" setup>
import { usePage } from '@inertiajs/vue3';
import { BaseColour, defaultIntertiaPropsInterface } from '@/compiler/types';

const isBillingAccountPastDue = (usePage().props as unknown as defaultIntertiaPropsInterface).isBillingAccountPastDue;
</script>
