import { ref } from 'vue';
import dayjs, { Dayjs } from 'dayjs';
import useFormatters from './useFormatters';

interface GetTimeFunction {
    (): string | Dayjs | undefined | null
}
interface AfterXSeconds {
    seconds: number
    callback: () => void
}

export default function useRelativeTimeCheck(
    getTimeFunction: GetTimeFunction,
    afterXSeconds: AfterXSeconds | null = null,
) {
    const { relativeWithTime } = useFormatters();
    const afterXSecondsRef = ref(afterXSeconds?.seconds ?? 30);

    const relativeLastCheck = ref<string | null>(null);
    const relativeLastCheckInterval = ref<number>();
    const setRelativeLastCheckInterval = () => {
        clearInterval(relativeLastCheckInterval.value);

        relativeLastCheckInterval.value = setInterval(() => {
            const time = getTimeFunction();
            if (!time) return;

            relativeLastCheck.value = relativeWithTime(time);

            if (!afterXSeconds) return;

            if (dayjs().diff(dayjs(time), 'second') > afterXSecondsRef.value) {
                afterXSeconds.callback();
            }
        }, 1000);
    };

    setRelativeLastCheckInterval();

    const stopChecking = () => {
        clearInterval(relativeLastCheckInterval.value);
    };

    const startChecking = () => {
        setRelativeLastCheckInterval();
    };

    const changeAfterXSeconds = (seconds: number) => {
        afterXSecondsRef.value = seconds;
    };

    return { relativeLastCheck, stopChecking, startChecking, changeAfterXSeconds };
}
