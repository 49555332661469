import _ from 'lodash';
import { defineStore } from 'pinia';
import { toRaw } from 'vue';
import API from '@/api';
import { MerchantUserRole } from '@/api/repositories/merchants';
import { ServiceProviderUserRole } from '@/api/repositories/serviceProviders';
import { userInterface, userOnboardingInterface } from '@/api/repositories/users';
import { AppEntitlementEnum, AppPermissionEnum } from '@/compiler/types';

interface State {
    user?: userInterface
    permissions?: Record<AppPermissionEnum, boolean>
    entitlements?: AppEntitlementEnum[]
    userRoles?: {
        serviceProvider: ServiceProviderUserRole[]
        merchant: MerchantUserRole[]
        platform: Array<'developer'>
    }
}

export const useCurrentUserStore = defineStore('currentUser', {
    state: (): State => ({
        user: undefined,
        permissions: undefined,
        userRoles: undefined,
        entitlements: undefined,
    }),
    getters: {
        currentUser: state => state.user,
    },
    actions: {
        async loadCurrentUser(): Promise<void> {
            if (this.user) {
                return;
            }

            await this.refreshCurrentUser();
        },
        async refreshCurrentUser(): Promise<void> {
            this.user = await API.repositories.users.fetchCurrentUser();
        },
        async loadPermissions(): Promise<void> {
            if (this.permissions) {
                return;
            }

            await this.refreshPermissions();
        },
        async refreshPermissions(): Promise<void> {
            this.permissions = await API.narrative.fetchCurrentPermissions();
        },
        async loadEntitlements(): Promise<void> {
            if (this.entitlements) {
                return;
            }

            this.entitlements = await API.narrative.fetchCurrentFeatures();
        },
        async loadUserRoles(): Promise<void> {
            if (this.userRoles) {
                return;
            }

            await this.refreshUserRoles();
        },
        async refreshUserRoles(): Promise<void> {
            this.userRoles = await API.narrative.fetchCurrentUserRoles();
        },
        updateUserOnboarding(onboarding: userOnboardingInterface): Promise<void> {
            if (!this.user || _.isEqual(toRaw(onboarding), toRaw(this.user.onboarding))) {
                return new Promise<void>(resolve => resolve());
            }

            this.user.onboarding = onboarding;

            return API.users.updateOnboarding(this.user.id, onboarding).then((onboardingResponse) => {
                if (!this.user) return;

                this.user.onboarding = onboardingResponse;
            });
        },
        can(permission: AppPermissionEnum): boolean {
            if (!this.permissions) return false;

            return this.permissions[permission];
        },
        hasEntitlement(entitlement: AppEntitlementEnum): boolean {
            if (!this.entitlements) return false;

            return this.entitlements.includes(entitlement);
        },
        userHasRoleOnCurrentServiceProvider(...roles: ServiceProviderUserRole[]): boolean {
            if (this.userRoles?.platform.includes('developer')) {
                return true;
            }

            if (!roles.length) {
                return Boolean(this.userRoles?.serviceProvider.length);
            }

            return Boolean(this.userRoles?.serviceProvider.some(role => roles.includes(role)));
        },
        userHasRoleOnCurrentMerchant(...roles: MerchantUserRole[]): boolean {
            if (this.userRoles?.platform.includes('developer')) {
                return true;
            }

            if (!roles.length) {
                return Boolean(this.userRoles?.merchant.length);
            }

            return Boolean(this.userRoles?.merchant.some(role => roles.includes(role)));
        },
    },
});
