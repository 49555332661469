import { debounce } from 'lodash';
import { reactive } from 'vue';
import { IdGetterFunction, RemoteSimplePaginationTabular, Tabular, sortInterface } from './types';
import { DataFetcherFunction } from '@/compiler/types';

export class RemotePingPongTable<T> implements Tabular<T>, RemoteSimplePaginationTabular<T> {
    private _fetcher: DataFetcherFunction<T>;

    private _data: T[] = [];
    private _idGetter: (item: T) => string | number;
    private _loading = false;
    private _pending = true;
    private _page = 1;
    private _pageSize = 10;
    private _from = 1;
    private _to = 10;
    private _total = 0;
    private _q = '';
    private _sorts: sortInterface[] = [];

    constructor(
        fetcher: DataFetcherFunction<T>,
        idGetter: IdGetterFunction<T>,
    ) {
        this._fetcher = fetcher;
        this._idGetter = idGetter;
    }

    static newReactive = <T>(
        fetcher: DataFetcherFunction<T>,
        idGetter: IdGetterFunction<T>,
    ): RemotePingPongTable<T> => {
        return reactive(new RemotePingPongTable<T>(fetcher, idGetter)) as RemotePingPongTable<T>;
    };

    get data() {
        return [...this._data];
    }

    get totalDataCount(): number {
        return this._total;
    }

    get isLoading(): boolean {
        return this._loading;
    }

    get isPending(): boolean {
        return this._pending;
    }

    get sorts() {
        return [...this._sorts];
    }

    private fetchData(
        page: number,
        perPage: number,
    ) {
        this._loading = true;
        return this
            ._fetcher(page, perPage, this._sorts, this._q)
            .then((response) => {
                this._data = response.data;
                this._page = response.currentPage;
                this._pageSize = response.perPage;
                this._from = response.from;
                this._to = response.to;
                this._total = response.total;
            })

            .finally(() => {
                this._loading = false;
                this._pending = false;
            });
    };

    fetchDataDebounce = debounce(this.fetchData, 50);
    fetchDataDebounceLong = debounce(this.fetchData, 300);

    requestData(page: number, pageSize?: number) {
        page = Math.max(1, page);
        this._pending = true;

        if (pageSize) {
            this._pageSize = pageSize;
        } else {
            pageSize = this.pageSize;
        }

        pageSize = Math.max(1, pageSize);

        if (page === this.page) {
            return this.fetchDataDebounceLong(page, pageSize);
        }

        return this.fetchDataDebounce(page, pageSize);
    }

    getCurrentData() {
        return this.data;
    }

    getId(item: T) {
        return this._idGetter(item);
    }

    // Sorting
    getSorts() {
        return this.sorts;
    }

    getSortFor = (field: string): sortInterface | undefined => {
        const sort = this.getSorts().find(sort => sort.field === field);
        if (!sort) {
            return undefined;
        }

        return sort;
    };

    sortBy(field: string, direction: 'asc' | 'desc' = 'asc', order: number = 1) {
        const index = this._sorts.findIndex(sort => sort.field === field);

        if (index !== -1) {
            this._sorts.splice(index, 1);
        }

        this._sorts.unshift({
            field,
            direction,
            order,
        });

        if (this._sorts.length > 1) {
            this._sorts[1].order = 2;
        }

        this._sorts.splice(2);

        this.requestData(this.page, this.pageSize);
    }

    clearSort(key: string) {
        this._sorts = this._sorts.filter(sort => sort.field !== key);

        this.requestData(this.page, this.pageSize);
    }

    // Filters
    setSearch(query: string) {
        this._q = query;

        this.requestData(this.page, this.pageSize);
    }

    get search(): string {
        return this._q;
    }

    // Pagination
    set page(page: number) {
        this.goToPage(page);
    }

    get page() {
        return this._page;
    }

    setPageSize(size: number) {
        if (size === this.pageSize) {
            return;
        }

        this.requestData(
            Math.min(this.page, this.getMaxPageForSize(size)),
            size,
        );
    }

    get pageSize() {
        return this._pageSize;
    }

    get pageStart() {
        return this._from;
    }

    get pageEnd() {
        return this._to;
    }

    goToPage(page: number) {
        if (page < 1) {
            page = 0;
        }

        const maxPage = this._page ? this.getMaxPageForSize(this._pageSize) : Number.MAX_SAFE_INTEGER;

        if (page > maxPage) {
            page = maxPage;
        }

        if (page === this._page) {
            return;
        }

        this.requestData(
            page,
            this._pageSize,
        );
    }

    private getMaxPageForSize = (size: number): number => {
        return Math.max(0, Math.ceil(this.totalDataCount / size));
    };
}
