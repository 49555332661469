import _ from 'lodash';
import Shepherd from 'shepherd.js';
import Step from 'shepherd.js/src/types/step';
import Tour from 'shepherd.js/src/types/tour';
import useAppEntitlements from './useAppEntitlements';
import { useNarrative } from './useNarrative';
import { useCurrentUserStore } from '@/stores/currentUser';
import { userOnboardingInterface } from '@/api/repositories/users';

export interface progressStepInterface {
    name: string
    clickFunc: Function | null
}

export enum availableToursEnum {
    CompanyAdmin = 'companyAdmin',
    CompanyUser = 'companyUser',
    ClientUser = 'clientUser',
    ShipmentIndex = 'shipmentIndex',
    InboundShipment = 'inboundShipment',
    OutboundShipment = 'outboundShipment',
    Inventory = 'inventory',
    Billing = 'billing',
}

export default function useUserOnboarding() {
    const { hasOrdersEntitlement } = useAppEntitlements();
    const { currentServiceProvider, appName, currentUser, userHasRoleOnCurrentServiceProvider, currentMerchantOptional, userIsOwnerOnServiceProvider, userIsOwnerOnMerchant } = useNarrative();
    const currentUserStore = useCurrentUserStore();

    const closeNotifications = () => {
        const closeNotificationsInterval = setInterval(() => {
            const notificationCloseButton = document.querySelector('.baseNotification .closeButton');
            if (notificationCloseButton) {
                clearInterval(closeNotificationsInterval);
                (notificationCloseButton as HTMLElement).click();
            }
        }, 100);
    };

    const showStepInTour = (tour: Tour, type: availableToursEnum, stepNumber: number) => {
        if (Shepherd.activeTour?.isActive()) return;

        const onboardingType = currentUser.value.onboarding?.[type];

        if (onboardingType === 'complete') {
            return;
        }
        if (onboardingType === 'cancelled') {
            return;
        }

        if (onboardingType && onboardingType > stepNumber) {
            stepNumber = onboardingType;
        }

        const stepId = `${type}OnboardingStep-${stepNumber}`;

        let startTourTries = 0;
        const pageLoadedInterval = setInterval(() => {
            if (document.querySelector('button[dusk="profile-nav-button"]')) {
                if (++startTourTries > 20) {
                    clearInterval(pageLoadedInterval);
                }

                // If this element isn't available, it usually means the tour is waiting for a different page to be loaded
                const attachToTourElement = tour.getById(stepId)?.options.attachTo?.element as string;
                if (attachToTourElement && !document.querySelector(attachToTourElement)) {
                    return;
                }

                clearInterval(pageLoadedInterval);

                const shepherdAlreadyExists = document.querySelector('.shepherd-enabled.shepherd-element');

                if (!tour.isActive() && shepherdAlreadyExists) {
                    shepherdAlreadyExists.remove();
                    document.querySelector('.shepherd-modal-is-visible.shepherd-modal-overlay-container')?.remove();
                }

                // Calling show when the tour is already active will show the next element, since the tour step is saved on the hide event
                if (tour.isActive()) return;

                tour.show(stepId);
            }
        }, 200);
    };

    const handleShowEvent = (type: availableToursEnum, step: Step) => {
        const numberFromId = Number.parseInt(step.id.replace(`${type}OnboardingStep-`, ''));
        const onboarding = _.cloneDeep((currentUser.value.onboarding) || {}) as userOnboardingInterface;
        const onboardingType = onboarding[type] as number;
        if (onboardingType && onboardingType > numberFromId) {
            return;
        }

        onboarding[type] = numberFromId;

        currentUserStore.updateUserOnboarding(onboarding);
    };

    const resetTour = (type: availableToursEnum): Promise<void> => {
        const onboarding = _.cloneDeep((currentUser.value.onboarding) || {}) as userOnboardingInterface;
        onboarding[type] = 1;

        return currentUserStore.updateUserOnboarding(onboarding);
    };

    const handleCompleteEvent = (type: availableToursEnum) => {
        const onboarding = _.cloneDeep((currentUser.value.onboarding) || {}) as userOnboardingInterface;
        onboarding[type] = 'complete';

        currentUserStore.updateUserOnboarding(onboarding);
    };

    const handleCancelledEvent = (type: availableToursEnum) => {
        const onboarding = _.cloneDeep((currentUser.value.onboarding) || {}) as userOnboardingInterface;
        onboarding[type] = 'cancelled';

        currentUserStore.updateUserOnboarding(onboarding);
    };

    const setupTour = (type: availableToursEnum): Tour => {
        const tour = new Shepherd.Tour({
            useModalOverlay: true,
            defaultStepOptions: {
                cancelIcon: {
                    enabled: true,
                    label: 'Cancel Tour',
                },
                popperOptions: {
                    modifiers: [{ name: 'offset', options: { offset: [0, 25] } }],
                },
                scrollTo: { behavior: 'smooth', block: 'center' },
                beforeShowPromise: () => new Promise<void>((resolve, reject) => {
                    let loadedCheckCount = 0;
                    const tourElementsLoaded = setInterval(() => {
                        if (++loadedCheckCount > 50) {
                            clearInterval(tourElementsLoaded);

                            reject(Error);
                        }

                        const currentStep = Shepherd.activeTour?.getCurrentStep();

                        if (!currentStep) { return; }

                        const attachToTourElement = currentStep.options.attachTo?.element as string;

                        if (attachToTourElement && !document.querySelector(attachToTourElement)) {
                            return;
                        }

                        const advanceOnTourElement = currentStep.options.advanceOn?.selector as string;

                        if (advanceOnTourElement && !document.querySelector(advanceOnTourElement)) {
                            return;
                        }

                        clearInterval(tourElementsLoaded);

                        resolve();
                    }, 200);
                }),
                when: {
                    show: () => {
                        const currentStep = Shepherd.activeTour?.getCurrentStep();
                        if (!currentStep) { return; }
                        const currentStepElement = currentStep.getElement();
                        if (!currentStepElement) { return; }
                        const header = currentStepElement.querySelector('.shepherd-header');
                        if (!header) { return; }
                        const progress = document.createElement('span');
                        progress.style.marginRight = '10px';
                        progress.classList.add('text-primary-200');
                        progress.textContent = `(${tour.steps.indexOf(currentStep) + 1}/${tour.steps.length})`;
                        header.insertBefore(progress, currentStepElement.querySelector('.shepherd-cancel-icon'));
                        handleShowEvent(type, currentStep);
                    },
                },
            },
        });

        tour.on('complete', () => {
            handleCompleteEvent(type);
        });

        tour.on('cancel', () => {
            handleCancelledEvent(type);
        });

        return tour;
    };

    const startCompanyAdminOnboarding = () => {
        if (currentUser.value.onboarding?.companyAdmin === 'complete') {
            return;
        }
        if (currentUser.value.onboarding?.companyAdmin === 'cancelled') {
            return;
        }

        const tour = setupTour(availableToursEnum.CompanyAdmin);
        let stepNumber = 1;

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: `Welcome to ${appName.value}!`,
            text: `Over the next few minutes, you'll get your account configured and ready to handle clients, as well as explore the features provided by ${appName.value}.`,
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Visit Account Settings',
            text: 'Let\'s get your account set up. First, we\'ll configure your account settings. Open your profile menu.',
            attachTo: {
                element: 'button[dusk="profile-nav-button"]',
                on: 'left',
            },
            advanceOn: {
                selector: 'button[dusk="profile-nav-button"]',
                event: 'click',
            },
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Visit Account Settings',
            text: 'Click Account Settings.',
            attachTo: {
                element: 'a[href="/staff/settings"]',
                on: 'left',
            },
            advanceOn: {
                selector: 'a[href="/staff/settings"]',
                event: 'click',
            },
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Domain Settings',
            text: 'In your domain settings, you can set up the URL address you would like your customers to access your site from. For more details on setting this up, please view our support article.',
            attachTo: {
                element: 'div[dusk="company-settings-domain-component"]',
                on: 'right',
            },
            canClickTarget: false,
            buttons: [
                {
                    text: 'Open Support Article',
                    action: () => window.open('https://help.prepbusiness.com/articles/domain-settings/', '_blank'),
                },
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Branding Settings',
            text: 'In your branding settings, you can upload a logo and icon to display to your client in the top right. You can also change the background behind the logo to dark or light, depending on what works best with your logo. Go ahead and upload a logo now if you like! Reload the page to see your new logo.',
            attachTo: {
                element: 'div[dusk="company-settings-branding-component"]',
                on: 'right',
            },
            advanceOn: {
                selector: 'div[dusk="company-settings-branding-component"] button.bg-primary-600',
                event: 'click',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'View Configuration',
            text: 'In your view configuration settings, there are a variety of parameters you can edit to match your preference. Take a look and update any that are relevant to you.',
            attachTo: {
                element: 'div[dusk="company-settings-view-configuration-component"]',
                on: 'right',
            },
            advanceOn: {
                selector: 'div[dusk="company-settings-view-configuration-component"] button.bg-primary-600',
                event: 'click',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Stripe Account',
            text: 'We integrate with Stripe (payments and billing software) to manage invoicing and process payments. If you don\'t have an account right now, it\'s simple to get started, just click the Connect to Stripe button to be guided through the process.',
            attachTo: {
                element: 'div[dusk="company-settings-stripe-component"]',
                on: 'left',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Billing Settings',
            text: 'Here you can set the number of days of free storage you provide your clients, and if you would like to be automatically billed monthly or only when you decide to bill them (manual).',
            attachTo: {
                element: 'div[dusk="company-settings-billing-component"]',
                on: 'left',
            },
            advanceOn: {
                selector: 'div[dusk="company-settings-billing-component"] button.bg-primary-600',
                event: 'click',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Default Email',
            text: 'The email you configure here will be the email address that clients receive notifications from, as well as the address that we will send notifications to by default.',
            attachTo: {
                element: 'div[dusk="company-settings-support-component"]',
                on: 'left',
            },
            advanceOn: {
                selector: 'div[dusk="company-settings-support-component"] button.bg-primary-600',
                event: 'click',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Warehouse Address',
            text: 'Next, let\'s set up the address that your clients will be sending their shipments to, and the address used for outbound shipments.',
            attachTo: {
                element: 'a[href="/staff/warehouses"]',
                on: 'right',
            },
            advanceOn: {
                selector: 'a[href="/staff/warehouses"]',
                event: 'click',
            },
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Warehouse Address',
            text: 'There is already a default warehouse that was set up for you. Click the name of the warehouse to edit the details of the warehouse.',
            attachTo: {
                element: '#warehousesIndexTable tbody tr',
                on: 'bottom',
            },
            advanceOn: {
                selector: '#warehousesIndexTable tbody tr',
                event: 'click',
            },
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Warehouse Address',
            text: 'Edit the values in the form to match your warehouse location. Click Update to save and continue.',
            attachTo: {
                element: 'div[dusk="warehouse-view-component"] div.overflow-hidden',
                on: 'right-start',
            },
            scrollTo: false,
            arrow: false,
            advanceOn: {
                selector: 'div[dusk="warehouse-view-component"] button.bg-primary-600',
                event: 'click',
            },
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Configure Services',
            text: 'Awesome! All that\'s left is to set up the services that you provide your clients, and invite your first client to the software. Open the profile menu to start configuring services.',
            attachTo: {
                element: 'button[dusk="profile-nav-button"]',
                on: 'left',
            },
            advanceOn: {
                selector: 'button[dusk="profile-nav-button"]',
                event: 'click',
            },
            when: {
                show: closeNotifications,
            },
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Configure Services',
            text: 'Click Services.',
            attachTo: {
                element: 'a[href="/staff/services"]',
                on: 'left',
            },
            advanceOn: {
                selector: 'a[href="/staff/services"]',
                event: 'click',
            },
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Configure Services',
            text: 'On this page is a list of all the various services that your company offers your clients. We\'ve prepopulated some popular ones, but you can delete and edit any of these to reflect your preferences.',
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Configure Services',
            text: 'Let\'s try editing a service. The highlighted service is an "always" charge, meaning that it gets charged for any outbound shipment item. This is great for defining a base per item charge. Click the edit button to update this service.',
            attachTo: {
                element: '.isBaseItemService',
                on: 'top',
            },
            advanceOn: {
                selector: '.isBaseItemService button.bg-primary-600',
                event: 'click',
            },
            showOn: () => {
                return Boolean(document.querySelector('.isBaseItemService'));
            },
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Configure Services',
            text: 'This is the client-visible name of the service. This should be descriptive enough so your clients understand the charge.',
            attachTo: {
                element: '#nameInput',
                on: 'right',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
            showOn: () => {
                return Boolean(document.querySelector('.isBaseItemService'));
            },
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Configure Services',
            text: 'This is a list of all the types of services that you can bill to the client.',
            attachTo: {
                element: '#typeInput',
                on: 'right',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
            showOn: () => {
                const path_regex = /^\/staff\/services\/\d+$/;

                return path_regex.test(location.pathname);
            },
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Configure Services',
            text: 'The unit is the client-visible identifier for the component used for this service. For example, if you are charging for each bag used, the unit would be "bag".',
            attachTo: {
                element: '#unitInput',
                on: 'right',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
            showOn: () => {
                const path_regex = /^\/staff\/services\/\d+$/;

                return path_regex.test(location.pathname);
            },
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Configure Services',
            text: 'The charge is the amount that is billed to the client for each unit. On this base shipment item charge, for example, the per unit charge is billed for every item in a shipment.',
            attachTo: {
                element: '#chargeInput',
                on: 'right',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
            showOn: () => {
                const path_regex = /^\/staff\/services\/\d+$/;

                return path_regex.test(location.pathname);
            },
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Configure Services',
            text: 'You can decide if this service should be billed to every item, or if the service needs to be added to a specific item (attached). Most services you define will be attached services.',
            attachTo: {
                element: '#whenToChargeInput',
                on: 'right',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
            showOn: () => {
                const path_regex = /^\/staff\/services\/\d+$/;

                return path_regex.test(location.pathname);
            },
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Configure Services',
            text: 'Go ahead and make any updates to this service that you need to, and then click the Update button.',
            attachTo: {
                element: '#editServiceCard',
                on: 'right',
            },
            advanceOn: {
                selector: '#editServiceCard button[dusk="Update"]',
                event: 'click',
            },
            showOn: () => {
                const path_regex = /^\/staff\/services\/\d+$/;

                return path_regex.test(location.pathname);
            },
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Invite a Client',
            text: 'The last step before we wrap up this tour is learning how to invite clients to the portal. Open the profile menu to navigate to the clients page.',
            attachTo: {
                element: 'button[dusk="profile-nav-button"]',
                on: 'left',
            },
            advanceOn: {
                selector: 'button[dusk="profile-nav-button"]',
                event: 'click',
            },
            when: {
                show: closeNotifications,
            },
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Invite a Client',
            text: 'Click Clients.',
            attachTo: {
                element: 'a[href="/staff/merchants"]',
                on: 'left',
            },
            advanceOn: {
                selector: 'a[href="/staff/merchants"]',
                event: 'click',
            },
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Invite a Merchant',
            text: 'Click Invite Merchant.',
            attachTo: {
                element: 'button[dusk="InviteMerchant"]',
                on: 'left',
            },
            advanceOn: {
                selector: 'button[dusk="InviteMerchant"]',
                event: 'click',
            },
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: 'Invite a Merchant',
            text: 'Fill in your merchant details here to invite your first merchant. If you\'re not ready to invite a merchant, just return to this screen when you\'re ready.',
            attachTo: {
                element: '#createMerchantCard',
                on: 'right',
            },
            advanceOn: {
                selector: '#createMerchantCard button[dusk="InviteMerchant"]',
                event: 'click',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: `companyAdminOnboardingStep-${stepNumber++}`,
            title: `Welcome to ${appName.value}!`,
            text: 'This completes your set up process! Feel free to revisit any of the pages we went through on the tour to update and refine your settings. If you need any additional help, you can visit our help desk or get in touch with your account manager who will be happy to assist.',
            buttons: [
                {
                    text: 'Visit Help Center',
                    action: () => window.open('https://help.prepbusiness.com/', '_blank'),
                },
                {
                    text: 'Finish Tour',
                    action: tour.complete,
                },
            ],
        });

        if (location.href.includes('staff/settings')) {
            const settingsPageLoadedInterval = setInterval(() => {
                if (document.querySelector('div[dusk="company-settings-domain-component"]')) {
                    clearInterval(settingsPageLoadedInterval);

                    showStepInTour(tour, availableToursEnum.CompanyAdmin, 5);
                }
            }, 50);
        }

        if (location.href.includes('staff/warehouses')) {
            const warehousesPageLoadedInterval = setInterval(() => {
                if (document.querySelector('#warehouseRow-1')) {
                    clearInterval(warehousesPageLoadedInterval);

                    showStepInTour(tour, availableToursEnum.CompanyAdmin, 11);
                }
                if (document.querySelector('div[dusk="warehouse-view-component"]')) {
                    clearInterval(warehousesPageLoadedInterval);

                    showStepInTour(tour, availableToursEnum.CompanyAdmin, 12);
                }
            }, 50);
        }

        if (location.href.includes('staff/services')) {
            const servicesPageLoadedInterval = setInterval(() => {
                if (document.querySelector('#tableFilter')) {
                    clearInterval(servicesPageLoadedInterval);

                    showStepInTour(tour, availableToursEnum.CompanyAdmin, 15);
                }
                if (document.querySelector('#editServiceCard')) {
                    clearInterval(servicesPageLoadedInterval);

                    showStepInTour(tour, availableToursEnum.CompanyAdmin, 17);
                }
            }, 50);
        }

        if (location.href.includes('staff/merchants')) {
            const servicesPageLoadedInterval = setInterval(() => {
                if (document.querySelector('#clientListCard')) {
                    clearInterval(servicesPageLoadedInterval);

                    showStepInTour(tour, availableToursEnum.CompanyAdmin, 26);
                }
                if (document.querySelector('#createMerchantCard')) {
                    clearInterval(servicesPageLoadedInterval);

                    showStepInTour(tour, availableToursEnum.CompanyAdmin, 27);
                }
            }, 50);
        }

        showStepInTour(tour, availableToursEnum.CompanyAdmin, 1);
    };

    const startCompanyUserOnboarding = () => {
        if (currentUser.value.onboarding?.companyUser === 'complete') {
            return;
        }
        if (currentUser.value.onboarding?.companyUser === 'cancelled') {
            return;
        }

        const tour = setupTour(availableToursEnum.CompanyUser);

        tour.addStep({
            id: 'companyUserOnboardingStep-1',
            title: `Welcome to ${appName.value}!`,
            text: `Let's take a few minutes to introduce you to the essential features provided by ${appName.value}. We'll briefly touch on each feature, and afterwards you can visit the page for that feature to get more details.`,
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'companyUserOnboardingStep-2',
            title: 'Client Dropdown',
            text: 'First, take a look at the client dropdown. When you don\'t have a client selected, you will see data for all clients. When you select a client, you can see specific information for that client, such as their inventory page or a list of just their shipments.',
            canClickTarget: false,
            attachTo: {
                element: '#clientSelect',
                on: 'bottom',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'companyUserOnboardingStep-3',
            title: 'Inbound Shipments',
            text: 'Inbound shipments are where you receive inventory items sent to you by your clients.',
            canClickTarget: false,
            attachTo: {
                element: 'a[href="/staff/shipments/inbound"]',
                on: 'right',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'companyUserOnboardingStep-4',
            title: 'Outbound Shipments',
            text: 'Outbound shipments are where you send in inventory in bulk from your warehouse to another location, such as Amazon FBA.',
            canClickTarget: false,
            attachTo: {
                element: 'a[href="/staff/shipments/outbound"]',
                on: 'right',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        if (hasOrdersEntitlement.value) {
            tour.addStep({
                id: 'companyUserOnboardingStep-5',
                title: 'Orders',
                text: 'Orders are where you can fulfill customer orders on behalf of your clients.',
                canClickTarget: false,
                attachTo: {
                    element: 'a[href="/staff/orders"]',
                    on: 'right',
                },
                buttons: [
                    {
                        text: 'Next',
                        action: tour.next,
                    },
                ],
            });
        }

        if (currentMerchantOptional.value) {
            tour.addStep({
                id: 'companyUserOnboardingStep-6',
                title: 'Inventory',
                text: 'On the inventory page, you can see all the available items for your client and their stock quantities.',
                canClickTarget: false,
                attachTo: {
                    element: 'a[href="/staff/inventory"]',
                    on: 'right',
                },
                buttons: [
                    {
                        text: 'Next',
                        action: tour.next,
                    },
                ],
            });
        } else {
            tour.addStep({
                id: 'companyUserOnboardingStep-6',
                title: 'Select a Client',
                text: 'The rest of the pages are hidden until you select a client. Go ahead and select a client now. If you don\'t have any clients yet, return here when you\'re ready to continue.',
                attachTo: {
                    element: '#clientSelect',
                    on: 'bottom',
                },
                when: undefined,
            });
        }

        if (userIsOwnerOnServiceProvider.value) {
            tour.addStep({
                id: 'companyUserOnboardingStep-7',
                title: 'Billing',
                text: 'On the billing page, you can see, edit, and add charges to your clients account, as well manage the details of how they are billed.',
                canClickTarget: false,
                attachTo: {
                    element: 'a[href="/staff/billing"]',
                    on: 'right',
                },
                buttons: [
                    {
                        text: 'Next',
                        action: tour.next,
                    },
                ],
            });
        }

        tour.addStep({
            id: 'companyUserOnboardingStep-8',
            title: 'Fix Issues',
            text: 'If there are problems with certain items on your client\'s account, such as a damaged or otherwise unsellable item, they will be listed here.',
            canClickTarget: false,
            attachTo: {
                element: 'a[href="/staff/actions/fix-items"]',
                on: 'right',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'companyUserOnboardingStep-9',
            title: `Welcome to ${appName.value}!`,
            text: 'That completes the features overview. Again, when you navigate to each page, there will be further guidance on using that page. If you need any other help, you can visit the help desk or reach out to your account manager.',
            buttons: [
                {
                    text: 'Visit Help Center',
                    action: () => window.open('https://help.prepbusiness.com/', '_blank'),
                },
                {
                    text: 'Finish Tour',
                    action: tour.complete,
                },
            ],
        });

        showStepInTour(tour, availableToursEnum.CompanyUser, 1);
    };

    const startClientUserOnboarding = () => {
        if (currentUser.value.onboarding?.clientUser === 'complete') {
            return;
        }
        if (currentUser.value.onboarding?.clientUser === 'cancelled') {
            return;
        }

        const tour = setupTour(availableToursEnum.ClientUser);

        tour.addStep({
            id: 'clientUserOnboardingStep-1',
            title: `Welcome to ${currentServiceProvider.value.name}!`,
            text: `Let's take a few minutes to introduce you to the essential features provided by ${currentServiceProvider.value.name}. We'll briefly touch on each feature, and afterwards you can visit the page for that feature to get more details.`,
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'clientUserOnboardingStep-3',
            title: 'Inbound Shipments',
            text: `Inbound shipments are where you send items to ${currentServiceProvider.value.name}. Inbound refers to inbound to their warehouse.`,
            canClickTarget: false,
            attachTo: {
                element: 'a[href="/shipments/inbound"]',
                on: 'right',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'clientUserOnboardingStep-4',
            title: 'Outbound Shipments',
            text: `Outbound shipments are where you can see the shipments going from ${currentServiceProvider.value.name} to another location, such as Amazon FBA.`,
            canClickTarget: false,
            attachTo: {
                element: 'a[href="/shipments/outbound"]',
                on: 'right',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        if (hasOrdersEntitlement.value) {
            tour.addStep({
                id: 'clientUserOnboardingStep-5',
                title: 'Orders',
                text: 'Orders are where you can see your customer orders.',
                canClickTarget: false,
                attachTo: {
                    element: 'a[href="/orders"]',
                    on: 'right',
                },
                buttons: [
                    {
                        text: 'Next',
                        action: tour.next,
                    },
                ],
            });
        }

        tour.addStep({
            id: 'clientUserOnboardingStep-6',
            title: 'Inventory',
            text: 'On the inventory page, you can see all your available items and their stock quantities.',
            canClickTarget: false,
            attachTo: {
                element: 'a[href="/inventory"]',
                on: 'right',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        if (userIsOwnerOnMerchant.value) {
            tour.addStep({
                id: 'clientUserOnboardingStep-7',
                title: 'Billing',
                text: 'On the billing page, you can see charges and invoices on your account.',
                canClickTarget: false,
                attachTo: {
                    element: 'a[href="/billing"]',
                    on: 'right',
                },
                buttons: [
                    {
                        text: 'Next',
                        action: tour.next,
                    },
                ],
            });
        }

        tour.addStep({
            id: 'clientUserOnboardingStep-8',
            title: 'Fix Issues',
            text: 'If there are problems with certain items on your account, such as a damaged or otherwise unsellable item, they will be listed here.',
            canClickTarget: false,
            attachTo: {
                element: 'a[href="/actions/fix-items"]',
                on: 'right',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'clientUserOnboardingStep-9',
            title: 'New Shipment',
            text: 'This is a shortcut to create a new inbound shipment.',
            canClickTarget: false,
            attachTo: {
                element: 'a[href="/shipments/inbound/create"]',
                on: 'right',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'clientUserOnboardingStep-10',
            title: `Welcome to ${currentServiceProvider.value.name}!`,
            text: 'That completes the features overview. Again, when you navigate to each page, there will be further guidance on using that page. If you need any other help, please get in touch!',
            buttons: [
                {
                    text: 'Finish Tour',
                    action: tour.complete,
                },
            ],
        });

        showStepInTour(tour, availableToursEnum.ClientUser, 1);
    };

    const startShipmentIndexOnboarding = () => {
        if (currentUser.value.onboarding?.shipmentIndex === 'complete') {
            return;
        }
        if (currentUser.value.onboarding?.shipmentIndex === 'cancelled') {
            return;
        }

        const tour = setupTour(availableToursEnum.ShipmentIndex);

        tour.addStep({
            id: 'shipmentIndexOnboardingStep-1',
            title: 'Shipments List',
            text: 'On this page you can see a list of shipments, including their status and number of units in the shipment.',
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'shipmentIndexOnboardingStep-2',
            title: 'Shipments List',
            text: 'To find a specific shipment, type the name of the shipment in here and the table will be filtered to show just that shipment.',
            attachTo: {
                element: '#tableFilter',
                on: 'bottom',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'shipmentIndexOnboardingStep-3',
            title: 'Shipments List',
            text: 'Click any shipments in this list to view or start working on that shipment.',
            attachTo: {
                element: 'table',
                on: 'top',
            },
            canClickTarget: false,
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'shipmentIndexOnboardingStep-4',
            title: 'Shipments List',
            text: 'Click New Shipment to create a new shipment.',
            attachTo: {
                element: '[dusk="NewShipment"]',
                on: 'left',
            },
            canClickTarget: false,
            buttons: [
                {
                    text: 'Finish Tour',
                    action: tour.complete,
                },
            ],
        });

        showStepInTour(tour, availableToursEnum.ShipmentIndex, 1);
    };

    const startInboundShipmentOnboarding = () => {
        if (currentUser.value.onboarding?.inboundShipment === 'complete') {
            return;
        }
        if (currentUser.value.onboarding?.inboundShipment === 'cancelled') {
            return;
        }

        const tour = setupTour(availableToursEnum.InboundShipment);

        tour.addStep({
            id: 'inboundShipmentOnboardingStep-1',
            title: 'Inbound Shipment',
            text: 'By default, you will see the list of items on the shipment. You can also switch to see services attached to the shipment or attached files. Click Services to see how to add services.',
            attachTo: {
                element: 'nav.space-x-8',
                on: 'top',
            },
            advanceOn: {
                selector: 'a[href="#services"]',
                event: 'click',
            },
        });

        tour.addStep({
            id: 'inboundShipmentOnboardingStep-2',
            title: 'Inbound Shipment',
            text: 'If there are already services attached to this shipment, they will be listed here. Use the Manage Services button to see available services and change the services attached to this shipment.',
            attachTo: {
                element: '#shipmentTabsCard',
                on: 'top',
            },
            canClickTarget: false,
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'inboundShipmentOnboardingStep-3',
            title: 'Inbound Shipment',
            text: 'Use the notes section to provide additional information or context about the shipment. The Notes section is editable by both clients and staff, and the Warehouse Notes section is only editable by staff.',
            attachTo: {
                element: '#shipmentNotesDiv', // Replace with new notes div ID when inbound shipment view page is done.
                on: 'bottom',
            },
            canClickTarget: false,
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'inboundShipmentOnboardingStep-4',
            title: 'Inbound Shipment',
            text: 'To add items to the shipment, click Add Items.',
            attachTo: {
                element: '[dusk="AddItems"]',
                on: 'left',
            },
            advanceOn: {
                selector: '[dusk="AddItems"]',
                event: 'click',
            },
            beforeShowPromise: () => {
                return new Promise<void>((resolve) => {
                    const itemsTabLink = document.querySelector('a[href="#items"]');
                    if (itemsTabLink) {
                        (itemsTabLink as HTMLElement).click();
                    }

                    resolve();
                });
            },
        });

        tour.addStep({
            id: 'inboundShipmentOnboardingStep-5',
            title: 'Inbound Shipment',
            text: 'From the add items overlay you can search for items and add them to the shipments.',
            buttons: [
                {
                    text: 'Finish Tour',
                    action: tour.complete,
                },
            ],
        });

        showStepInTour(tour, availableToursEnum.InboundShipment, 1);
    };

    const startOutboundShipmentOnboarding = () => {
        if (currentUser.value.onboarding?.outboundShipment === 'complete') {
            return;
        }
        if (currentUser.value.onboarding?.outboundShipment === 'cancelled') {
            return;
        }

        const tour = setupTour(availableToursEnum.OutboundShipment);

        tour.addStep({
            id: 'outboundShipmentOnboardingStep-1',
            title: 'Outbound Shipment',
            text: 'Outbound shipments are organized essentially the same as inbound shipments. If you have not completed that page guide yet, you may find it helpful to do so to understand this page.',
            buttons: [
                {
                    text: 'Finish Tour',
                    action: tour.complete,
                },
            ],
        });

        showStepInTour(tour, availableToursEnum.OutboundShipment, 1);
    };

    const startInventoryOnboarding = () => {
        if (currentUser.value.onboarding?.inventory === 'complete') {
            return;
        }
        if (currentUser.value.onboarding?.inventory === 'cancelled') {
            return;
        }

        const tour = setupTour(availableToursEnum.Inventory);

        tour.addStep({
            id: 'inventoryOnboardingStep-1',
            title: 'Inventory',
            text: 'The inventory page contains a list of your available items, quantity in stock, and associated services.',
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'inventoryOnboardingStep-2',
            title: 'Inventory',
            text: 'You can select items using the checkboxes on the left and perform a variety of actions from the Actions dropdown, such as adding the items to shipments or deleting them from your inventory.',
            attachTo: {
                element: '#inventoryActionsDropdown',
                on: 'right',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'inventoryOnboardingStep-3',
            title: 'Inventory',
            text: 'Search for items using the title, SKU, UPC, or any other identifier related to the item.',
            attachTo: {
                element: '#inventory-search',
                on: 'bottom',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'inventoryOnboardingStep-4',
            title: 'Inventory',
            text: 'Add new items to your inventory using a step-by-step process.',
            attachTo: {
                element: '[dusk="AddItem"]',
                on: 'left',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'inventoryOnboardingStep-5',
            title: 'Inventory',
            text: 'Or you can upload a spreadsheet of new items.',
            attachTo: {
                element: '[dusk="UploadSpreadsheet"]',
                on: 'left',
            },
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'inventoryOnboardingStep-6',
            title: 'Inventory',
            text: 'Click the SKU on any of the items to view or edit all of the item details.',
            attachTo: {
                element: 'table',
                on: 'top',
            },
            scrollTo: false,
            buttons: [
                {
                    text: 'Finish Tour',
                    action: tour.complete,
                },
            ],
        });

        showStepInTour(tour, availableToursEnum.Inventory, 1);
    };

    const startBillingOnboarding = () => {
        if (currentUser.value.onboarding?.billing === 'complete') {
            return;
        }
        if (currentUser.value.onboarding?.billing === 'cancelled') {
            return;
        }

        const tour = setupTour(availableToursEnum.Billing);

        tour.addStep({
            id: 'billingOnboardingStep-1',
            title: 'Billing',
            text: 'This page contains a list of charges and invoices.',
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'billingOnboardingStep-2',
            title: 'Billing',
            text: 'To view the details of any charge, just click directly on the charge row.',
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'billingOnboardingStep-3',
            title: 'Billing',
            text: 'You can switch view your invoices by switching to the invoices tab.',
            attachTo: {
                element: 'nav.space-x-8',
                on: 'bottom',
            },
            buttons: [
                {
                    text: userHasRoleOnCurrentServiceProvider.value ? 'Next' : 'Finish Tour',
                    action: userHasRoleOnCurrentServiceProvider.value ? tour.next : tour.complete,
                },
            ],
        });

        tour.addStep({
            id: 'billingOnboardingStep-4',
            title: 'Billing',
            text: 'You can create a manual charge on your client\'s account by clicking New Charge.',
            attachTo: {
                element: '[dusk="NewCharge"]',
                on: 'left',
            },
            canClickTarget: false,
            buttons: [
                {
                    text: 'Next',
                    action: tour.next,
                },
            ],
        });

        tour.addStep({
            id: 'billingOnboardingStep-5',
            title: 'Billing',
            text: 'If you are not using automatic billing, or if you would like to create an invoice outside the usual billing cycle, click the Invoice Now button.',
            attachTo: {
                element: '#invoiceNowButton',
                on: 'left',
            },
            canClickTarget: false,
            buttons: [
                {
                    text: 'Finish Tour',
                    action: tour.complete,
                },
            ],
        });

        showStepInTour(tour, availableToursEnum.Billing, 1);
    };

    const startTour = (type: availableToursEnum) => {
        switch (type) {
            case availableToursEnum.CompanyAdmin:
                startCompanyAdminOnboarding();
                break;
            case availableToursEnum.CompanyUser:
                startCompanyUserOnboarding();
                break;
            case availableToursEnum.ClientUser:
                startClientUserOnboarding();
                break;
            case availableToursEnum.ShipmentIndex:
                startShipmentIndexOnboarding();
                break;
            case availableToursEnum.InboundShipment:
                startInboundShipmentOnboarding();
                break;
            case availableToursEnum.OutboundShipment:
                startOutboundShipmentOnboarding();
                break;
            case availableToursEnum.Inventory:
                startInventoryOnboarding();
                break;
            case availableToursEnum.Billing:
                startBillingOnboarding();
                break;
        }
    };

    return {
        startTour,
        resetTour,
    };
}
