import { reject } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useAzlabelsTrayStore } from './azlabelsTray';
import { fetchCurrentStation, stationInterface } from '@/api/repositories/stations';
import useAzlabelsTray from '@/composables/useAzlabelsTray';

const { usbDeviceFromSlug } = useAzlabelsTray();

export const useStationsStore = defineStore('stations', () => {
    const azlabelsTrayStore = useAzlabelsTrayStore();
    const currentStation = ref<stationInterface | null>(null);
    const gettingCurrentStation = ref(false);

    const shippingLabelPrinterStatus = computed((): 'pending' | 'available' | 'offline' => {
        if (!currentStation.value?.shippingLabelPrinter) {
            return 'offline';
        }
        if (!azlabelsTrayStore.printers.length && !azlabelsTrayStore.printersLoadedAt) {
            return 'pending';
        }
        if (azlabelsTrayStore.printers.includes(currentStation.value.shippingLabelPrinter)) {
            return 'available';
        }
        return 'offline';
    });

    const barcodeLabelPrinterStatus = computed((): 'pending' | 'available' | 'offline' => {
        if (!currentStation.value?.barcodeLabelPrinter) {
            return 'offline';
        }
        if (!azlabelsTrayStore.printers.length && !azlabelsTrayStore.printersLoadedAt) {
            return 'pending';
        }
        if (azlabelsTrayStore.printers.includes(currentStation.value.barcodeLabelPrinter)) {
            return 'available';
        }
        return 'offline';
    });

    const scaleStatus = computed((): 'pending' | 'available' | 'offline' => {
        if (!currentStation.value?.scale) {
            return 'offline';
        }
        if (!azlabelsTrayStore.usbDevices.length && !azlabelsTrayStore.usbDevicesLoadedAt) {
            return 'pending';
        }
        const usbDevice = usbDeviceFromSlug(currentStation.value.scale);
        if (!usbDevice) {
            return 'offline';
        }
        if (azlabelsTrayStore.usbDevices.find(device => device.productId === usbDevice.productId && device.vendorId === usbDevice.vendorId)) {
            return 'available';
        }
        return 'offline';
    });

    const isCheckingDevices = computed((): boolean => {
        return azlabelsTrayStore.loadingPrinters || azlabelsTrayStore.loadingUsbDevices;
    });

    const loadCurrentStation = async (): Promise<void> => {
        return new Promise<void>((resolve) => {
            if (gettingCurrentStation.value) {
                reject('getting station now');
            }

            gettingCurrentStation.value = true;

            return fetchCurrentStation().then((station) => {
                currentStation.value = station;
                gettingCurrentStation.value = false;
                azlabelsTrayStore.loadPrinters().catch(error => console.error(error));
                azlabelsTrayStore.loadUsbDevices();

                resolve();
            });
        });
    };

    const refreshDevices = () => {
        azlabelsTrayStore.loadPrinters();
        azlabelsTrayStore.loadUsbDevices();
    };

    return {
        currentStation,
        gettingCurrentStation,
        shippingLabelPrinterStatus,
        barcodeLabelPrinterStatus,
        scaleStatus,
        isCheckingDevices,
        lastDeviceCheck: computed(() => azlabelsTrayStore.lastDeviceCheck),
        loadCurrentStation,
        refreshDevices,
    };
});
