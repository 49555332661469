import { snakeCase } from 'lodash';
import { orderInterface, pickDataInterface, pickInterface } from '../repositories/orders';
import axios from '@/helpers/forms/axios';

export interface pickDataRequestInterface {
    type: string
    skipped: boolean
    code: string | null
}

const assignPicks = async (orders: orderInterface[]): Promise<void> => {
    return await axios.post('/api/orders/assignPicksBySelected', { orders });
};

const markAsPicked = async (picks: pickInterface[]): Promise<pickInterface[]> => {
    const response = await axios.post<{ picks: pickInterface[] }>('/api/orders/batch/markAsPicked', { picks });

    return response.data.picks;
};

const assignPicksByPriority = async (ordersToPickQuantity: number): Promise<void> => {
    return await axios.post('/api/orders/assignPicksByPriority', { ordersToPickQuantity });
};

const updatePickData = async (orderItemId: number, data: pickDataRequestInterface): Promise<pickDataInterface> => {
    data.type = snakeCase(data.type);
    if (data.code === null) {
        delete (data as any).code;
    }

    const response = await axios.post<{ pickData: pickDataInterface }>(`/api/order-items/${orderItemId}/scan`, data);

    return response.data.pickData;
};

const printPickList = async (): Promise<string> => {
    const response = await axios.get<{ url: string }>('/api/orders/pickList');

    return response.data.url;
};

export default {
    assignPicks,
    markAsPicked,
    assignPicksByPriority,
    updatePickData,
    printPickList,
};
