import { cashierCustomerAccountInterface, cashierPartyInterface, cashierPaymentMethods, cashierPaymentProviderAccountInterface, cashierProfileTypes, cashierStripeCardInterface, cashierStripePaymentMethodSetupInterface, cashierStripeProfileInterface } from '../cashier';
import { sendForm } from '@/helpers/forms/form';
import axios, { fetchPaginatedDataFunction } from '@/helpers/forms/axios';

const fakeCashierPaymentMethod: cashierStripeCardInterface = {
    id: '324aabaa-63af-4401-8ddb-8e2c588ac0b1',
    profileId: '224aabaa-63af-4401-8ddb-8e2c588ac0b1',
    isActive: true,
    isTrashed: false,
    state: 'active',
    last4: '4242',
    brand: 'visa',
    funding: 'credit',
    expiryYear: 2022,
    expiryMonth: 12,
    stripePaymentMethodId: 'pm_123',
};

const fakeCashierProfile: cashierStripeProfileInterface = {
    id: '224aabaa-63af-4401-8ddb-8e2c588ac0b1',
    accountId: '124aabaa-63af-4401-8ddb-8e2c588ac0b1',
    defaultPaymentMethodId: '324aabaa-63af-4401-8ddb-8e2c588ac0b1',
    state: 'active',
    name: 'John Doe',
    emailAddress: 'client@example.org',
    isActive: true,
    isTrashed: false,
};

const fakeCashierOrganization: cashierPartyInterface = {
    id: '424aabaa-63af-4401-8ddb-8e2c588ac0b1',
    name: 'ABC Organization',
    email: 'organization@example.org',
};

const fakeCashierPaymentProviderAccount: cashierPaymentProviderAccountInterface = {
    id: '724aabaa-63af-4401-8ddb-8e2c588ac0b1',
    name: 'Stripe',
    party: fakeCashierOrganization,
};

const fetchCashierParties = fetchPaginatedDataFunction<cashierPartyInterface>('/api/admin/cashier/parties');

export interface CreateCashierPartyForm {
    name: string
    email: string
}

const createCashierParty = async (form: CreateCashierPartyForm): Promise<cashierPartyInterface> => {
    const response = await sendForm({
        uri: '/api/admin/cashier/parties',
        method: 'post',
        form,
    });

    return response.data;
};

const fetchCashierCustomerAccounts = fetchPaginatedDataFunction<cashierCustomerAccountInterface>('/api/admin/cashier/customer-accounts');

const fetchCashierCustomerAccount = async (customerAccountId: string): Promise<cashierCustomerAccountInterface> => {
    const response = await axios.get<cashierCustomerAccountInterface>(`/api/admin/cashier/customer-accounts/${customerAccountId}`);

    return response.data;
};

export interface CreateCashierCustomerAccountForm {
    currency: string
    customerId: string
    organizationId: string
}

const createCashierCustomerAccount = async (form: CreateCashierCustomerAccountForm): Promise<cashierCustomerAccountInterface> => {
    const response = await sendForm({
        uri: '/api/admin/cashier/customer-accounts',
        method: 'post',
        form,
    });

    return response.data;
};

const fetchCashierProfiles = async (accountId: string): Promise<cashierProfileTypes[]> => {
    const response = await axios.get<{ data: cashierProfileTypes[] }>(`/api/admin/cashier/profiles?filters%5B%5D=account,=,${accountId}`);
    return response.data.data;
};

const buildFilterQueryParam = (filterKey: string, filterValue: string) => `?filters%5B%5D=${filterKey},=,${filterValue}`;

const fetchCashierPaymentSetups = async (profileId?: string): Promise<cashierStripePaymentMethodSetupInterface[]> => {
    const fullUrl = profileId ? `${'/api/admin/cashier/payment-method-setups/'}${buildFilterQueryParam('profile', profileId)}` : '/api/admin/cashier/payment-method-setups';
    const response = await axios.get<{ data: cashierStripePaymentMethodSetupInterface[] }>(fullUrl);
    return response.data.data;
};

const fetchCashierPaymentMethods = async (profileId?: string): Promise<cashierPaymentMethods[]> => {
    const fullUrl = profileId ? `${'/api/admin/cashier/payment-methods/'}${buildFilterQueryParam('profile', profileId)}` : '/api/admin/cashier/payment-methods';
    const response = await axios.get<{ data: cashierPaymentMethods[] }>(fullUrl);
    return response.data.data;
};

const fetchCashierPaymentProviderAccounts = async (): Promise<cashierPaymentProviderAccountInterface[]> => {
    return new Promise<cashierPaymentProviderAccountInterface[]>((resolve) => {
        resolve([fakeCashierPaymentProviderAccount]);
    });
    const response = await axios.get<{ data: cashierPaymentProviderAccountInterface[] }>(`/api/admin/cashier/payment-provider-accounts'`);

    return response.data.data;
};

const setCashierProfileAsDefault = async (profileId: string): Promise<cashierProfileTypes> => {
    const response = await axios.post<cashierProfileTypes>(`/api/admin/cashier/profiles/${profileId}/make-default`);

    return response.data;
};

const trashCashierProfile = async (profileId: string): Promise<cashierProfileTypes> => {
    const response = await axios.delete<cashierProfileTypes>(`/api/admin/cashier/profiles/${profileId}`);

    return response.data;
};

const restoreCashierProfile = async (profileId: string): Promise<cashierProfileTypes> => {
    const response = await axios.post<cashierProfileTypes>(`/api/admin/cashier/profiles/${profileId}/restore`);

    return response.data;
};

const activateCashierStripeProfile = async (profileId: string): Promise<cashierStripeProfileInterface> => {
    const response = await axios.post<cashierProfileTypes>(`/api/admin/cashier/profiles/stripe/${profileId}/activate`);

    return response.data;
};

const reconnectCashierStripeProfile = async (profileId: string): Promise<cashierStripeProfileInterface> => {
    return new Promise<cashierStripeProfileInterface>((resolve) => {
        resolve(fakeCashierProfile);
    });

    const response = await axios.post<{ data: cashierProfileTypes }>(`/api/admin/cashier/profiles/${profileId}/stripe/reconnect`);

    return response.data.data;
};

const closeCashierStripeProfile = async (profileId: string): Promise<cashierStripeProfileInterface> => {
    return new Promise<cashierStripeProfileInterface>((resolve) => {
        resolve(fakeCashierProfile);
    });

    const response = await axios.post<{ data: cashierProfileTypes }>(`/api/admin/cashier/profiles/${profileId}/stripe/close`);

    return response.data.data;
};

const setCashierPaymentMethodAsDefault = async (paymentMethodId: string): Promise<cashierPaymentMethods> => {
    return new Promise<cashierPaymentMethods>((resolve) => {
        resolve(fakeCashierPaymentMethod);
    });

    const response = await axios.post<{ data: cashierPaymentMethods }>(`/api/admin/cashier/payment-methods/${paymentMethodId}/make-default`);

    return response.data.data;
};

const trashCashierPaymentMethod = async (paymentMethodId: string): Promise<cashierPaymentMethods> => {
    return new Promise<cashierPaymentMethods>((resolve) => {
        resolve(fakeCashierPaymentMethod);
    });

    const response = await axios.delete<{ data: cashierPaymentMethods }>(`/api/admin/cashier/payment-methods/${paymentMethodId}`);

    return response.data.data;
};

const restoreCashierPaymentMethod = async (paymentMethodId: string): Promise<cashierPaymentMethods> => {
    return new Promise<cashierPaymentMethods>((resolve) => {
        resolve(fakeCashierPaymentMethod);
    });

    const response = await axios.post<{ data: cashierPaymentMethods }>(`/api/admin/cashier/payment-methods/${paymentMethodId}/restore`);

    return response.data.data;
};

export default {
    // Parties
    fetchCashierParties,
    createCashierParty,

    // Accounts
    fetchCashierCustomerAccounts,
    fetchCashierCustomerAccount,
    createCashierCustomerAccount,

    // Profiles
    fetchCashierProfiles,
    fetchCashierPaymentMethods,
    fetchCashierPaymentSetups,
    fetchCashierPaymentProviderAccounts,
    setCashierProfileAsDefault,
    trashCashierProfile,
    restoreCashierProfile,
    activateCashierStripeProfile,
    reconnectCashierStripeProfile,
    closeCashierStripeProfile,
    setCashierPaymentMethodAsDefault,
    trashCashierPaymentMethod,
    restoreCashierPaymentMethod,
};
