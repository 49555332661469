import { moneyInterface } from '@/compiler/types';
import axios from '@/helpers/forms/axios';

export interface billingAccountInterface {
    id: string
    createdAt: string
    defaultPaymentMethodId?: string
}

export interface billingPeriodInterface {
    id: string
    billingAccountId: string
    startDate: string
    endDate: string
}

export interface usagePriceTierInterface {
    fromUnits: number
    toUnits: number | null
    amountPerUnit: number
}

export interface usagePriceInterface {
    usageType: 'outbound_shipment_items' | 'orders'
    tiers: usagePriceTierInterface[]
}

export interface subscriptionInterface {
    id: string
    planPriceId: string
    renewalDate: string
    startedAt: string
    endsAt?: string
    trialEndsAt?: string
}

export interface subscriptionPlanInterface {
    id: string
    name: string
    trialDays: number
}

export interface subscriptionPlanPriceInterface {
    id: string
    plan: subscriptionPlanInterface
    price: moneyInterface
    intervalType: 'month' | 'year'
    usagePrices: usagePriceInterface[]
    active: boolean
}

export interface usageRecordInterface {
    id: string
    billingPeriodId: string
    billingPeriod: billingPeriodInterface
    type: 'outbound_shipment_items' | 'orders'
    amount: number
}

export interface usageStatisticsResult {
    outboundShipmentItems?: {
        currentUnits: number
        lastTwelveMonthsUnits: number[]
    }
    orders?: {
        currentUnits: number
        lastTwelveMonthsUnits: number[]
    }
}

export interface paymentMethodInterface {
    id: string
    createdAt: string
    type: 'card'
    lastFour: string
}

const fetchBillingAccount = async (): Promise<billingAccountInterface> => {
    const response = await axios.get<{ data: billingAccountInterface }>('/api/service-provider-billing/current-account');

    return response.data.data;
};

const fetchBillingPeriod = async (forDate?: string): Promise<billingPeriodInterface> => {
    const url = new URL('/api/service-provider-billing/billing-period', location.origin);

    if (forDate) {
        url.searchParams.append('forDate', forDate);
    }

    const response = await axios.get<{ data: billingPeriodInterface }>(url.toString());

    return response.data.data;
};

const fetchSubscriptionPlanPrices = async (): Promise<subscriptionPlanPriceInterface[]> => {
    const response = await axios.get<{ data: subscriptionPlanPriceInterface[] }>('/api/service-provider-billing/plans');

    return response.data.data;
};

const fetchActiveSubscriptions = async (): Promise<subscriptionInterface[]> => {
    const response = await axios.get<{ data: subscriptionInterface[] }>('/api/service-provider-billing/subscriptions');

    return response.data.data;
};

const fetchUsageRecords = async (): Promise<usageRecordInterface[]> => {
    const response = await axios.get<{ data: usageRecordInterface[] }>('/api/service-provider-billing/usage-records');

    return response.data.data;
};

const fetchUsageStatistics = async (): Promise<usageStatisticsResult> => {
    const response = await axios.get<usageStatisticsResult>('/api/service-provider-billing/usage-statistics');

    return response.data;
};

const subscribeToPlanPrice = async (planPriceId: string) => {
    return await axios.post('/api/service-provider-billing/subscriptions/create', { planPriceId });
};

const replaceSubscription = async (subscriptionId: string, planPriceId: string) => {
    return await axios.post('/api/service-provider-billing/subscriptions/replace', { subscriptionId, planPriceId });
};

const cancelSubscription = async (subscriptionId: string) => {
    return await axios.post('/api/service-provider-billing/subscriptions/cancel', { subscriptionId });
};

export default {
    fetchBillingAccount,
    fetchBillingPeriod,
    fetchActiveSubscriptions,
    fetchSubscriptionPlanPrices,
    fetchUsageRecords,
    fetchUsageStatistics,
    replaceSubscription,
    subscribeToPlanPrice,
    cancelSubscription,
};
