import applyCaseMiddleware from 'axios-case-converter';
import baseAxios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { camelCase, camelCaseTransformMerge } from 'camel-case';
import { snakeCase } from 'change-case';
import usePopupNotifications from '@/composables/usePopupNotifications';
import { useSessionExpiredStore } from '@/stores/overlays/sessionExpired';
import { useNarrative } from '@/composables/useNarrative';
import { DataFetcherFunction, DataFetcherResponse, DataFetcherSort } from '@/compiler/types';

const client = baseAxios.create();
const clientWithoutCaseMiddleware = baseAxios.create();

const applyInterceptors = (client: AxiosInstance) => {
    client.interceptors.request.use((request: InternalAxiosRequestConfig) => {
        request.headers.Accept = 'application/json';

        const { currentMerchantOptional, currentServiceProviderOptional } = useNarrative();

        if (currentMerchantOptional.value) {
            request.headers['X-Selected-Client-Id'] = currentMerchantOptional.value.id.toString();
        }

        if (currentServiceProviderOptional.value) {
            request.headers['X-Service-Provider-Id'] = currentServiceProviderOptional.value.id.toString();
        }

        return request;
    });

    client.interceptors.response.use((response: AxiosResponse<any>) => {
        if (response.data.message) {
            usePopupNotifications().notifySuccess(response.data.message);
        }
        return response;
    }, (error) => {
        if (error.response === undefined) {
            return Promise.reject(error);
        }

        switch (error.response.status) {
            case 401:
            case 419:
                useSessionExpiredStore().setSessionExpired(true);
                break;
            case 402:
                window.location.href = '/settings#/subscription';
                break;
            case 403:
                if (typeof (error.response.data.message) !== 'undefined') {
                    handle403(error);
                }
                break;
            case 404:
                if (typeof (error.response.data.message) !== 'undefined') {
                    handle404(error);
                }
                break;
            case 405:
                handle405();
                break;
            case 400:
            case 409:
            case 422:
                if (typeof (error.response.data.errors) !== 'undefined') {
                    handle422(error.response.data.errors);
                } else if (error.response.data.message) {
                    usePopupNotifications().notifyError(error.response.data.message);
                }
                break;
            case 500:
                if (typeof (error.response.data.message) !== 'undefined') {
                    handle500(error);
                }
                break;
        }

        return Promise.reject(error);
    });
};

applyInterceptors(client);
applyInterceptors(clientWithoutCaseMiddleware);

export const axiosWithoutCaseMiddleware = clientWithoutCaseMiddleware;

applyCaseMiddleware(client, {
    caseFunctions: {
        camel: (input) => {
            return camelCase(input, { transform: camelCaseTransformMerge });
        },
        snake: (input) => {
            return snakeCase(input, {
                stripRegexp: /[^A-Z0-9[\]]+/gi,
                splitRegexp: [/([a-z0-9])([A-Z])/g, /([A-Z])([A-Z][a-z])/g, /([a-zA-Z])([0-9])/g],
            });
        },
    },
});

function handle422(errors: any) {
    if (Array.isArray(errors)) {
        errors.forEach((error: any) => {
            handle422(error);
        });

        return;
    }

    if (typeof errors === 'string') {
        usePopupNotifications().notifyError(errors);

        return;
    }

    Object.keys(errors).forEach((key) => {
        const errorFromKey = errors[key];
        if (typeof errorFromKey === 'string') {
            usePopupNotifications().notifyError(errorFromKey);

            return;
        }

        errors[key].forEach((error: string) => {
            usePopupNotifications().notifyError(error);
        });
    });
}

function handle500(errors: any) {
    if (errors.response.data.message) {
        usePopupNotifications().notifyError(errors.response.data.message);
    } else {
        usePopupNotifications().notifyError('Server error processing this request. Please report this error to our support team.');
    }

    showSentryFeedback();
}

function handle403(errors: any) {
    if (!errors.response.data.message) {
        usePopupNotifications().notifyError('This action is unauthorized.');
    } else {
        usePopupNotifications().notifyError(errors.response.data.message);
    }
}

function handle404(errors: any) {
    if (!errors.response.data.message) {
        usePopupNotifications().notifyError('The route could not be found, please report this error to our support team.');
    } else {
        usePopupNotifications().notifyError(`${errors.response.data.message} Please report this error to our support team.`);
    }
}

function handle405() {
    usePopupNotifications().notifyError('Method not allowed. Please report this error to our support team.');
}

function showSentryFeedback() {
    if (!useNarrative().sentryPublicDsn.value) {
        return;
    }

    import('@/composables/useSentry').then((module) => {
        module.default().showFeedback();
    });
}

export const fetchPaginatedDataFunction = <T>(
    route: string,
): DataFetcherFunction<T> => {
    const dataFetcher: DataFetcherFunction<T> = async (
        page: number,
        pageSize: number,
        sorts: DataFetcherSort[],
        filters: string,
    ): Promise<DataFetcherResponse<T>> => {
        const uri = new URL(route, window.location.origin);

        uri.searchParams.append('page', page.toString());
        uri.searchParams.append('per_page', pageSize.toString());
        if (filters.length > 0) {
            uri.searchParams.append('q', filters);
        }
        sorts.forEach((sort, index) => {
            Object.entries(sort).forEach(([key, value]) => {
                uri.searchParams.append(`sorts[${index}][${key}]`, value);
            });
        });
        const response = await client.get<DataFetcherResponse<T>>(uri.toString());

        return response.data;
    };

    return dataFetcher;
};

export default client;
